
import { Options, Vue } from 'vue-class-component';

import Lightbox from "@/components/lightbox/lightbox.vue";
import Carousel from "@/components/carousel/carousel.vue";

import { event } from 'vue-gtag';

@Options({
  components: {
    Lightbox,
    Carousel
  },
  computed: {
    CMS_URL () {
      return this.$store.getters.getCMSURL;
    }/*,
    activePost () {
      return this.$store.getters.getActivePost;
    },
    relatedPost() {
      return this.$store.getters.getRelatedPost;
    }*/
  },
  watch: {
    '$route' (to) {

      if(to.name == 'magazinepost') {
        //console.log(to);


        this.getPostByID(to.params.id);

      }

      // react to route changes...
    }
  }
})
export default class BlogPost extends Vue {

  CMS_URL;
  listeners = [];
  activePost = null;
  relatedPost = null;
  parsed = false;
  scrolled = false;
  public lightboxSrc = null;
  public lightboxCaption = null;
  public authorPosts = null;
  public authorData = null;
  dragging = false;
  dragX = 0;
  fullSlide = [];
  currentIndex = 0;
  currentLang = 'english';

  created() {
    //console.log(this.$route.params.id);
    this.getPost();
  }

  mounted() {
    let cookies = (this.$parent as any).$root.cookies,
      gdprCookie = cookies.get("gdprCookie"),
      id = this.$route.params.id;

    if(gdprCookie == 'true') {

      event(null, { 
        event: null, // Event type [default = 'interaction'] (Optional)
        category: 'Magazine Post',
        action: 'click',
        label: 'Magazine Post Draft View: '+id,
        value: 5000,
        noninteraction: false, // Optional
      });

    }

    if(this.activePost && !this.parsed) {
      this.parseDoc();
    }

    window.addEventListener('resize', this.handleResize);

    let twitterScript = document.createElement('script');
    twitterScript.setAttribute('src', 'https://platform.twitter.com/widgets.js' );
    document.head.appendChild(twitterScript);

    twitterScript.onload = () => {
      //console.log("hi");
      (window as any).twttr.widgets.load();
    }

    

    //window.addEventListener('scroll', this.handleScroll);

  }

  beforeUnmount() {
    this.removeListener();
  }

  removeListener() {
    window.removeEventListener('resize', this.handleResize);
    this.removeGalleryEvent();
  }

  handleResize() {
    //console.log('resize');
    this.resetGallery();
  }

  getPost() {
    let id = this.$route.params.id;
    //if(!this.activePost) {
      //console.log('get post--',id);
      this.getPostByID(id);
    /*} else {
      console.log(this.activePost);
      this.getRelatedPost(this.activePost.custom_fields.author.ID, id);
    }*/
  }

  getPostByID(id) {

    this.axios.get(this.CMS_URL + 'mt7rk/get_post_by_id&id=' + id).then((response) => {
      //console.log(response.data);
      //this.$store.commit('setActivePost', JSON.parse(response.data));
      this.activePost = JSON.parse(response.data);
      //console.log(this.activePost);
      setTimeout(() => {
        this.relatedPost = null;

        this.parseDoc();
        //console.log(this.activePost.custom_fields);
        //if(this.activePost.custom_fields.author)
         this.getRelatedPost(this.$route.params.id, this.$route.params.id);
          
          let instaScript = document.createElement('script');
          instaScript.setAttribute('src', 'http://platform.instagram.com/en_US/embeds.js' );
          document.head.appendChild(instaScript);

          instaScript.onload = () => {
            //console.log("hi");
            //console.log(window as any);
            (window as any).instgrm.Embeds.process();
          }

          let behanceScript = document.createElement('script');
          behanceScript.setAttribute('src', 'https://iframely.net/embed.js');
          document.head.appendChild(behanceScript);
          behanceScript.onload = () => {
            //console.log(window as any);
            (window as any).iframely.load();
          }

          this.parseYoutube();
      }, 10);
    });
  }

  getRelatedPost(id,ex) {
    //console.log( this.activePost.category[0].term_id);
    //if (!this.relatedPost) {
      //console.log('author id',id);
    if(this.activePost.category.length > 0) {
      this.axios.get(this.CMS_URL + 'mt7rk/get_posts_by_tag&tag=' + this.activePost.category[0].term_id + '&ex=' + ex).then((response) => {
        //console.log(response.data);
        //this.$store.commit('setRelatedPost', response.data);
        this.relatedPost = response.data;
        this.$forceUpdate();
        //console.log(this.relatedPost);
      });
    } 
  }

  parseDoc() {
      this.parsed = true;
      //console.log(window);

      this.parseImages();

      this.parseGallery();

      this.parseEmbeds();

     
      
  }

  parseEmbeds() {
    this.parseTwitter();

  }

  goBack() {
    if((window as any).history.state.back) {
      this.$router.go(-1);
    } else {
      this.$router.push({
        name: 'magazine',
      });
    }
    //
  }

  parseTwitter() {
    let tweets = document.getElementsByClassName('wp-block-embed is-provider-twitter');

    for(let i = 0; i < tweets.length; i++) {
      let id = (tweets[i] as any).innerText.split('/');
      id = id[id.length-1];
      //console.log(id);
      (window as any).twttr.widgets.createTweet(
        id,
        tweets[i],
        {}
      );
              //console.log((tweets[i] as any).innerText.split('/'));
      /*let html = `<iframe style="border:none;" width="550" height="700" data-tweet-url="https://twitter.com/buitengebieden/status/1590063312611704832" srcdoc="<blockquote class='twitter-tweet'><p lang='en' dir='ltr'>Dogs are the best.. 😊 <a href='https://t.co/LcSxAcWpwm'>pic.twitter.com/LcSxAcWpwm</a></p>&amp;mdash; Buitengebieden (@buitengebieden) <a href='https://twitter.com/buitengebieden/status/1590063312611704832?ref_src=twsrc%5Etfw'>November 8, 2022</a></blockquote>"></iframe>`;
      console.log(html);
      (tweets[i] as any).innerHTML = html;

      if(i == tweets.length-1) {
        let twitterScript = document.createElement('script');
        twitterScript.setAttribute('src', 'https://platform.twitter.com/widgets.js' );
        document.head.appendChild(twitterScript);

        twitterScript.onload = () => {
          console.log("hi");
          (window as any).twttr.widgets.load();
        }
      }*/
    } 
  }

  parseYoutube() {
    let iframes = document.querySelectorAll('iframe');

    for(let i = 0; i < iframes.length; i++) {
      if(iframes[i].src.indexOf('youtube') !== -1) {
        iframes[i].classList.add("youtube-embed");
      }
      //console.log(iframes[i].src.indexOf('youtube'));
    }
  }

  scrollToAuthor() {
    let author = document.getElementsByClassName('author-wrap');
    if(author[0]) {
      window.scrollTo(0,(author[0] as any).offsetTop);
    }
  }

  parseImages() {
    let images = document.getElementsByClassName("wp-block-image"),
      html = "<div class='magnifying-glass'></div>",
      num = 0;

      for(let i = 0; i < images.length; i++) {
        //console.log((images[i] as any));
        //images[i].children[0].innerHTML = images[i].children[0].innerHTML + html;

        if(images[i].children[0].children[0]) {
          (images[i].children[0].children[0] as any).dataset.full_image = (images[i].children[0] as any).href;
          if((images[i].children[0] as any).parentElement.children[1])
            (images[i].children[0].children[0] as any).dataset.caption = (images[i].children[0] as any).parentElement.children[1].innerHTML;
          this.listeners.push(images[i].children[0].addEventListener('click', this.imageClickHandler));
          
          setTimeout(() => {
            //if(images[i].children[0].children[1]) {
              images[i].classList.add('box-shadow');
              images[i].classList.add('bc-red');
              images[i].classList.add('ltbx');
              images[i].setAttribute('data-index',String(num));
              images[i].setAttribute('data-full_image',(images[i].children[0].children[0] as any).dataset.full_image);
              // add click event for lightbox
              this.listeners.push(images[i].addEventListener('click', this.imageClickHandler));
              // create lightbox gallery
              this.fullSlide.push([(images[i].children[0].children[0] as any).dataset.full_image,(images[i].children[1] ? images[i].children[1].innerHTML : null)]);
              //console.log(this.fullSlide);
              num++;
            //}

          }, 10);

        }
      }
  }

  parseGallery() {
    //console.log(this.fullSlide);
    let galleries = document.getElementsByClassName("wp-block-gallery");
    let html = "<div class='arrow arrow-left box-shadow right reversed'><img src='"+require('@/assets/img/arrow.svg')+"'/></div><div class='arrow arrow-right box-shadow reversed'><img src='"+require('@/assets/img/arrow.svg')+"'/></div>";

    for(let i = 0; i < galleries.length; i++) {
      galleries[i].innerHTML = galleries[i].innerHTML + html;

      setTimeout(() => {
        this.listeners.push(galleries[i].children[galleries[i].children.length-1].addEventListener('click', this.sliderHandler));
        this.listeners.push(galleries[i].children[galleries[i].children.length-2].addEventListener('click', this.sliderHandler));
        this.listeners.push(galleries[i].addEventListener('touchstart', this.startDrag));
        this.listeners.push(galleries[i].addEventListener('mousedown', this.startDrag));

        this.listeners.push(galleries[i].addEventListener('touchend', this.endDrag));
        this.listeners.push(galleries[i].addEventListener('mouseup', this.endDrag));
        // set gallery nav according to the window
        this.resetGallery();

      }, 10);
    }
  }

  removeGalleryEvent() {
    let galleries = document.getElementsByClassName("wp-block-gallery");
    for(let i = 0; i < galleries.length; i++) {
      this.listeners.push(galleries[i].children[galleries[i].children.length-1].removeEventListener('click', this.sliderHandler));
      this.listeners.push(galleries[i].children[galleries[i].children.length-2].removeEventListener('click', this.sliderHandler));
      this.listeners.push(galleries[i].removeEventListener('touchstart', this.startDrag));
      this.listeners.push(galleries[i].removeEventListener('mousedown', this.startDrag));

      this.listeners.push(galleries[i].removeEventListener('touchend', this.endDrag));
      this.listeners.push(galleries[i].removeEventListener('mouseup', this.endDrag));
    }
  }

  resetGallery() {
    let galleries = document.getElementsByClassName("wp-block-gallery"),
      totalElements,
      width;

    for(let i = 0; i < galleries.length; i++) {
      totalElements = galleries[i].children.length-2;
      width = totalElements*360;

      if(width <= window.innerWidth)
        galleries[i].classList.add("centered");
      else
        galleries[i].classList.remove("centered");

      galleries[i].classList.add("hide-prev");
      galleries[i].classList.remove("hide-next");
      galleries[i].firstElementChild.classList.add("reset");
    }
  }

  sliderHandler(e, dir=null) {
    let trgt;
    if(dir == null) {
      trgt = e.currentTarget.parentElement;
      dir = (e.currentTarget.classList.contains('arrow-left') ? 1 : -1);
    } else {
      trgt = e.currentTarget;
    }

    let reset = trgt.firstElementChild.className,
      currentMargin;
    // detect reset when we resize the window
    if(reset.includes("reset")) {
      currentMargin = 0;
    } else {
      currentMargin = (trgt.children[0].style["margin-left"] ? trgt.children[0].style["margin-left"].slice(0,-2) : 0);
    }

    let totalElements = trgt.childElementCount-2, 
      totalWidth = ((totalElements)*360 - window.innerWidth + (currentMargin*10)),
      margin,
      width;

    if(dir == -1 ) { //going left
      trgt.firstElementChild.classList.remove("reset");
      trgt.classList.remove("hide-prev");
      //get first element of slider from our current target
      if(totalWidth >= 360) {
        trgt.children[0].style["margin-left"] = currentMargin-36+'em';
      } else if(totalWidth > 0){
        trgt.children[0].style["margin-left"] = currentMargin-(totalWidth/10)+'em';
      } else {
        trgt.classList.add("hide-next");
      }
      // hide nav we are at the end
      margin = trgt.children[0].style["margin-left"].slice(0,-2) * 10,
      width = (totalElements*360) - window.innerWidth;
      
      if(margin == -width) {
        trgt.classList.add("hide-next");
      }

    } else if(dir == 1 && currentMargin < 0) { //going right
      trgt.classList.remove("hide-next");
      trgt.children[0].style["margin-left"] = (parseInt(currentMargin)+36 > 0 ? 0 : parseInt(currentMargin)+36)+'em';
      // hide nav if we are at the begining
      margin = trgt.children[0].style["margin-left"].slice(0,-2);

      if(margin == 0) {
        trgt.classList.add("hide-prev");
      }
    } 
  }

  startDrag(event) {
    this.dragging = true;
    //console.log("sup");

    if(event.type == 'touchstart') {
      this.dragX = event.touches[0].clientX;
    } else {
      this.dragX = event.x;
    }
  }

  endDrag(event) {
    
    this.dragging = false;

    let x = (event.type == 'touchend' ? event.changedTouches[0].clientX : event.x),
      elem = event.target.offsetParent;
    
    if(elem.classList.contains('arrow-right') || elem.classList.contains('arrow-left')) {
      if (elem.classList.contains('arrow-left')) {
        this.sliderHandler(event, 1);
      } else {
        this.sliderHandler(event, -1);
      }
    } else {
      if(Math.abs(x - this.dragX) > 7) {

        if(x < this.dragX) {
          this.sliderHandler(event, -1);
        } else if(x > this.dragX){
          this.sliderHandler(event, 1);

        }
      } else if(event.which !== 3) {
        this.imageClickHandler(event);
      }
    }
    event.preventDefault();
  }

  imageClickHandler(e) {
    e.preventDefault();
    //console.log(e.target,this.currentIndex);
    
    if(e.target.dataset.full_image) {
      this.lightboxSrc = e.target.dataset.full_image;
      this.currentIndex = e.target.dataset.index;

      if(e.target.dataset.caption)
        this.lightboxCaption = e.target.dataset.caption;
    } else {
      this.lightboxSrc = e.target.parentElement.href;
      this.currentIndex = e.target.dataset.index;

      if(e.target.parentElement.children[0].dataset.caption)
        this.lightboxCaption = e.target.parentElement.children[0].dataset.caption;
      else
        this.lightboxCaption = null;
    }

    //console.log(this.lightboxSrc);
    this.$forceUpdate();
  }

  closeLightbox() {
    //console.log('lightbox close');
    this.lightboxSrc = null;
    this.$forceUpdate();
  }

  openLightbox(src,e) {
    //console.log("click",src);
    this.lightboxSrc = src;
    this.$forceUpdate();
    if(e)
      e.preventDefault();
  }

  handleScroll() {
    //console.log(document.getElementsByClassName('img-wrap'))
    let el = (document.getElementsByClassName('img-wrap')[0] as any);
    if(window.scrollY > el.offsetTop + el.offsetHeight) {
      this.scrolled = true;
    } else if(this.scrolled == true && window.scrollY < el.offsetTop) {
      this.scrolled = false;
    }

    //this.scrollPos = window.scrollY;
  }

  goToAuthor(post,e) {
    this.$router.push({
      name: 'authorView',
      params: { id: decodeURIComponent(post.post_name) }
    });
    e.preventDefault();
  }

  changeLang(e) {
    if(this.currentLang == 'english')
      this.currentLang = 'arabic';
    else
      this.currentLang = 'english';

    setTimeout(() => {
      this.parseDoc();
    }, 100);
    
    e.preventDefault();
  }

}
