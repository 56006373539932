import { createStore } from 'vuex'

export default createStore({
  state () {
    return {
      osClass: null,
      deviceClass: null,
      screenClass: null,
      browserClass: null,
      page: null,
      scrollId: null,
      posts: null,
      blog: null,
      directory: null,
      awards: null,
      categories: null,
      tags: null,
      events: null,
      directories: null,
      activePost: null,
      relatedPost: null,
      gdprCookie: null,
      intro: false,
      CMS_URL: 'https://mt7rk.me/cms/?rest_route=/'
    }
  },
  getters: {
    getOSClass(state) {
      return (state as any).osClass;
    },
    getDeviceClass(state: any) {
      return (state as any).deviceClass;
    },
    getScreenClass(state: any) {
      return (state as any).screenClass;
    },
    getBrowserClass(state: any) {
      return (state as any).browserClass;
    },
    getPage(state: any) {
      return (state as any).page;
    },
    getScrollPosition(state: any) {
      return (state as any).scrollId;
    },
    getCMSURL(state: any) {
      return (state as any).CMS_URL;
    },
    getPosts(state: any) {
      const str = JSON.stringify((state as any).posts);
      return JSON.parse(str);
    },
    getBlog(state: any) {
      const str = JSON.stringify((state as any).blog);
      return JSON.parse(str);
    },
    getDirectory(state: any) {
      const str = JSON.stringify((state as any).directory);
      return JSON.parse(str);
    },
    getAwards(state: any) {
      const str = JSON.stringify((state as any).awards);
      return JSON.parse(str);
    },
    getCategories(state: any) {
      const str = JSON.stringify((state as any).categories);
      return JSON.parse(str);
    },
    getTags(state: any) {
      const str = JSON.stringify((state as any).tags);
      return JSON.parse(str);
    },
    getEvents(state: any) {
      const str = JSON.stringify((state as any).events);
      return JSON.parse(str);
    },
    getDirectories(state: any) {
      const str = JSON.stringify((state as any).directories);
      return JSON.parse(str);
    },
    getActivePost(state: any) {
      const str = JSON.stringify((state as any).activePost);
      return JSON.parse(str);
    },
    getRelatedPost(state: any) {
      const str = JSON.stringify((state as any).relatedPost);
      return JSON.parse(str);
    },
    getGdprCookie(state: any) {
      return (state as any).gdprCookie;
    },
    getIntro(state: any) {
      return (state as any).intro;
    }
    
  },
  mutations: {
    setOSClass(state, osClass: string) {
      (state as any).osClass = osClass;
    },
    setDeviceClass(state, deviceClass: string) {
      (state as any).deviceClass = deviceClass;
    },
    setScreenClass(state, screenClass: string) {
      (state as any).screenClass = screenClass;
    },
    setBrowserClass(state, browserClass: string) {
      (state as any).browserClass = browserClass;
    },
    setPage(state, page: string) {
      (state as any).page = page;
    },
    setScrollPosition(state, scrollId: any) {
      (state as any).scrollId = scrollId;
    },
    setPosts(state, posts: any) {
      (state as any).posts = posts;
    },
    setBlog(state, blog: any) {
      (state as any).blog = blog;
    },
    setDirectory(state, directory: any) {
      (state as any).directory = directory;
    },
    setAwards(state, awards: any) {
      (state as any).awards = awards;
    },
    setCategories(state, categories: any) {
      (state as any).categories= categories;
    },
    setTags(state, tags: any) {
      (state as any).tags = tags;
    },
    setEvents(state, events: any) {
      (state as any).events = events;
    },
    setDirectories(state, directories: any) {
      (state as any).directories = directories;
    },
    setActivePost(state, activePost: any) {
      (state as any).activePost = activePost;
    },
    setRelatedPost(state, relatedPost: any) {
      (state as any).relatedPost= relatedPost;
    },
    setGdprCookie(state, gdprCookie: any) {
      (state as any).gdprCookie= gdprCookie;
    },
    setIntro(state, intro: any) {
      (state as any).intro= intro;
    }
  },
  actions: {
  },
  modules: {
  }
})
